import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getUserProfileId } from '../../../../configuration/login/loginSlice';
import { useFetchAccountsQuery, useFetchUserQuery } from '../../services/usersApi';
import { useEffect, useState } from 'react';
import { Account } from '../../services/types';
import { getTenant } from '../../../../configuration/tokenHandling/tokenHandlingSlice';

const DEMO_FLEET_ACCOUNT_ID = '47bbd84b-b46d-4bdc-a9d7-eb36b70358ec';

export const UserInNavbar = () => {
    const [currentAccount, setCurrentAccount] = useState<Account | undefined>(undefined);

    const userProfileId = useAppSelector(getUserProfileId);
    const tenant = useAppSelector(getTenant);
    const isTestAccount = tenant?.includes('test');

    const { data: user } = useFetchUserQuery(userProfileId!);
    const { data: accounts } = useFetchAccountsQuery(userProfileId!);

    if (user === undefined) {
        return null;
    }

    useEffect(() => {
        const candidate = accounts?.find((acc: { active: boolean }) => acc.active);
        if (candidate) {
            setCurrentAccount(candidate);
        }
    }, [accounts]);

    const preferredLogin = user.email ? user.email : user.phoneNumber || '';
    return (
        <div className="margin-bottom-10 padding-y-10">
            <div className="display-flex align-items-center flex-column gap-10 line-height-125rel text-color-dark text-size-14">
                <div
                    className="ellipsis-1 max-width-250 text-size-16 text-bold"
                    title={`${user.firstName} ${user.lastName}`}
                >
                    {`${user.firstName} ${user.lastName}`}
                </div>
                <div className="ellipsis-1 max-width-250" title={preferredLogin}>
                    {preferredLogin}
                </div>
                {currentAccount && (
                    <div className="display-flex flex-wrap align-items-center justify-content-center">
                        <span className="badge badge-muted" style={{ cursor: 'default' }}>
                            {abbreviateString(currentAccount.name)}
                        </span>
                    </div>
                )}
                {currentAccount && isTestAccount === true && currentAccount.id !== DEMO_FLEET_ACCOUNT_ID && (
                    <div className="display-flex flex-wrap align-items-center justify-content-center">
                        <span className="badge badge-muted" style={{ cursor: 'default' }} title={'Account ID'}>
                            {abbreviateString(currentAccount.id)}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

const abbreviateString = (input: string) => {
    const maxLength = 56;

    // Check if the string needs to be abbreviated
    if (input.length > maxLength) {
        // Find the last space before the 60 character limit
        let lastSpace = input.substring(0, maxLength).lastIndexOf(' ');

        // If there is no space, or the space is very early in the string, we truncate at maxLength
        if (lastSpace === -1 || lastSpace < 30) {
            // 30 is arbitrary, adjust based on your needs
            return input.substring(0, maxLength) + ' ...';
        }

        // Return the string up to the last space and append ' ...'
        return input.substring(0, lastSpace) + ' ...';
    }

    // Return the original string if it's shorter than maxLength
    return input;
};
