import { createSlice } from '@reduxjs/toolkit';
import getOr from 'lodash/fp/getOr';
import { LoginState } from '../types';
import { RootState } from '../setup/store';

const initialState: LoginState = {
    hasUserSessionEverExpired: false,
    userProfile: null,
    userSessionExpired: false,
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        userProfileObtained: (state, action) => {
            state.userProfile = action.payload;
        },
        userSessionExpired: (state) => {
            state.hasUserSessionEverExpired = true;
            state.userSessionExpired = true;
        },
        userSessionRenewed: (state) => {
            state.userSessionExpired = false;
        },
    },
});

export const loginReducer = loginSlice.reducer;

const RIO_PROD_USER_POOL_PREFIX = 'prod-rio-users';

export const { userProfileObtained, userSessionRenewed, userSessionExpired } = loginSlice.actions;

export const getUserProfile = (state: RootState) => state.login.userProfile;

export const getUserAccount = getOr(null, 'login.userProfile.account');

export const hasUserSessionEverExpired = (state: RootState) => state.login.hasUserSessionEverExpired;

export const isUserSessionExpired = (state: RootState) => state.login.userSessionExpired;

export const getUserPool = (state: RootState) => {
    const subject = state.login.userProfile?.sub;
    return subject ? subject.split(':')[0] : undefined;
};

export const getIsRioProdUserPool = (state: RootState) => {
    const userPool = getUserPool(state);
    return userPool === RIO_PROD_USER_POOL_PREFIX;
};

export const getUserName = (state: RootState) => state.login.userProfile?.username;

export const getUserProfileId = (state: RootState) =>
    state.login.userProfile?.sub ? state.login.userProfile?.sub.split(':')[1] : undefined;
