import * as Sentry from '@sentry/browser';
import { config } from '../../config';
import type { Primitive } from '@sentry/types/types/misc';

const UNDEFINED_TOKEN = 'CREATE_RIO_FRONTEND_';

if (import.meta.env.PROD) {
    // version and environment are defined in the webpack.define plugin
    const release = config.serviceVersion;
    const environment = config.serviceEnvironment;
    const dsn = config.sentryToken;

    const hasToken = !!dsn && !dsn?.startsWith(UNDEFINED_TOKEN);

    if (hasToken) {
        Sentry.init({
            dsn,
            environment,
            release,
        });
    }
}

export const reportErrorToSentry = (...args: [any, any?]) => {
    Sentry.captureException(...args);
};

export const logMessageToSentry = (
    msg: string,
    tags?: {
        [key: string]: Primitive;
    }
) => {
    if (import.meta.env.PROD) {
        Sentry.captureMessage(msg, { tags });
    }
};
